.App {
  text-align: center;
}

.bg-primary-clr{
  background-color:#38468C;
}

.bg-primary-clr-2{
  background-color:#17396B;
}

.txt-title{
  color:#38468C;
}

.txt-title-2{
  color:#17396B;
}

.auth-container {
  background-image: url("/public/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.active-link {
  color: white;
  font-weight: bold;
}

.def-link .indicator{
  visibility: hidden;
}
.shadow-soft {
  box-shadow: 0rem 0.25rem 1.75rem rgba(30, 34, 40, 0.07);
}
