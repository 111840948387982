@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,400;1,500;1,700;1,900&display=swap');

html,
body {
  font-family: "Roboto", "Poppins", sans-serif !important;
  background-color: hsl(7, 43%, 96%) !important;
}

@layer components {
  .graph-table  {
    @apply grid grid-cols-3 gap-20
  }
}

@layer components {
  .row-header {
    @apply text-center text-[18px]
  }
}


*>.intro-x:first-child {
  transform: translateX(50px);
}

*>.-intro-x:first-child,
*>.intro-x:first-child {
  z-index: 49;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

*>.-intro-x:first-child {
  transform: translateX(-50px);
}

*>.intro-y:first-child {
  z-index: 49;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

*>.intro-x:nth-child(2) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(2),
*>.intro-x:nth-child(2) {
  z-index: 48;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

*>.-intro-x:nth-child(2) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(2) {
  z-index: 48;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

*>.intro-x:nth-child(3) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(3),
*>.intro-x:nth-child(3) {
  z-index: 47;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

*>.-intro-x:nth-child(3) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(3) {
  z-index: 47;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

*>.intro-x:nth-child(4) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(4),
*>.intro-x:nth-child(4) {
  z-index: 46;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

*>.-intro-x:nth-child(4) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(4) {
  z-index: 46;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

*>.intro-x:nth-child(5) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(5),
*>.intro-x:nth-child(5) {
  z-index: 45;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

*>.-intro-x:nth-child(5) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(5) {
  z-index: 45;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

*>.intro-x:nth-child(6) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(6),
*>.intro-x:nth-child(6) {
  z-index: 44;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

*>.-intro-x:nth-child(6) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(6) {
  z-index: 44;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

*>.intro-x:nth-child(7) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(7),
*>.intro-x:nth-child(7) {
  z-index: 43;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

*>.-intro-x:nth-child(7) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(7) {
  z-index: 43;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

*>.intro-x:nth-child(8) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(8),
*>.intro-x:nth-child(8) {
  z-index: 42;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

*>.-intro-x:nth-child(8) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(8) {
  z-index: 42;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

*>.intro-x:nth-child(9) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(9),
*>.intro-x:nth-child(9) {
  z-index: 41;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

*>.-intro-x:nth-child(9) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(9) {
  z-index: 41;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

*>.intro-x:nth-child(10) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(10),
*>.intro-x:nth-child(10) {
  z-index: 40;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

*>.-intro-x:nth-child(10) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(10) {
  z-index: 40;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

*>.intro-x:nth-child(11) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(11),
*>.intro-x:nth-child(11) {
  z-index: 39;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

*>.-intro-x:nth-child(11) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(11) {
  z-index: 39;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

*>.intro-x:nth-child(12) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(12),
*>.intro-x:nth-child(12) {
  z-index: 38;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

*>.-intro-x:nth-child(12) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(12) {
  z-index: 38;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

*>.intro-x:nth-child(13) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(13),
*>.intro-x:nth-child(13) {
  z-index: 37;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

*>.-intro-x:nth-child(13) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(13) {
  z-index: 37;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

*>.intro-x:nth-child(14) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(14),
*>.intro-x:nth-child(14) {
  z-index: 36;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

*>.-intro-x:nth-child(14) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(14) {
  z-index: 36;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

*>.intro-x:nth-child(15) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(15),
*>.intro-x:nth-child(15) {
  z-index: 35;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

*>.-intro-x:nth-child(15) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(15) {
  z-index: 35;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

*>.intro-x:nth-child(16) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(16),
*>.intro-x:nth-child(16) {
  z-index: 34;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

*>.-intro-x:nth-child(16) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(16) {
  z-index: 34;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

*>.intro-x:nth-child(17) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(17),
*>.intro-x:nth-child(17) {
  z-index: 33;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

*>.-intro-x:nth-child(17) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(17) {
  z-index: 33;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

*>.intro-x:nth-child(18) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(18),
*>.intro-x:nth-child(18) {
  z-index: 32;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

*>.-intro-x:nth-child(18) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(18) {
  z-index: 32;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

*>.intro-x:nth-child(19) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(19),
*>.intro-x:nth-child(19) {
  z-index: 31;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

*>.-intro-x:nth-child(19) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(19) {
  z-index: 31;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

*>.intro-x:nth-child(20) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(20),
*>.intro-x:nth-child(20) {
  z-index: 30;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

*>.-intro-x:nth-child(20) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(20) {
  z-index: 30;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

*>.intro-x:nth-child(21) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(21),
*>.intro-x:nth-child(21) {
  z-index: 29;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

*>.-intro-x:nth-child(21) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(21) {
  z-index: 29;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

*>.intro-x:nth-child(22) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(22),
*>.intro-x:nth-child(22) {
  z-index: 28;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.2s;
  animation-delay: 2.2s;
}

*>.-intro-x:nth-child(22) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(22) {
  z-index: 28;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.2s;
  animation-delay: 2.2s;
}

*>.intro-x:nth-child(23) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(23),
*>.intro-x:nth-child(23) {
  z-index: 27;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.3s;
  animation-delay: 2.3s;
}

*>.-intro-x:nth-child(23) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(23) {
  z-index: 27;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.3s;
  animation-delay: 2.3s;
}

*>.intro-x:nth-child(24) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(24),
*>.intro-x:nth-child(24) {
  z-index: 26;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

*>.-intro-x:nth-child(24) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(24) {
  z-index: 26;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

*>.intro-x:nth-child(25) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(25),
*>.intro-x:nth-child(25) {
  z-index: 25;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

*>.-intro-x:nth-child(25) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(25) {
  z-index: 25;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

*>.intro-x:nth-child(26) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(26),
*>.intro-x:nth-child(26) {
  z-index: 24;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
}

*>.-intro-x:nth-child(26) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(26) {
  z-index: 24;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
}

*>.intro-x:nth-child(27) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(27),
*>.intro-x:nth-child(27) {
  z-index: 23;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.7s;
  animation-delay: 2.7s;
}

*>.-intro-x:nth-child(27) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(27) {
  z-index: 23;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.7s;
  animation-delay: 2.7s;
}

*>.intro-x:nth-child(28) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(28),
*>.intro-x:nth-child(28) {
  z-index: 22;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}

*>.-intro-x:nth-child(28) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(28) {
  z-index: 22;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}

*>.intro-x:nth-child(29) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(29),
*>.intro-x:nth-child(29) {
  z-index: 21;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.9s;
  animation-delay: 2.9s;
}

*>.-intro-x:nth-child(29) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(29) {
  z-index: 21;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2.9s;
  animation-delay: 2.9s;
}

*>.intro-x:nth-child(30) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(30),
*>.intro-x:nth-child(30) {
  z-index: 20;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

*>.-intro-x:nth-child(30) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(30) {
  z-index: 20;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

*>.intro-x:nth-child(31) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(31),
*>.intro-x:nth-child(31) {
  z-index: 19;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.1s;
  animation-delay: 3.1s;
}

*>.-intro-x:nth-child(31) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(31) {
  z-index: 19;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.1s;
  animation-delay: 3.1s;
}

*>.intro-x:nth-child(32) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(32),
*>.intro-x:nth-child(32) {
  z-index: 18;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.2s;
  animation-delay: 3.2s;
}

*>.-intro-x:nth-child(32) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(32) {
  z-index: 18;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.2s;
  animation-delay: 3.2s;
}

*>.intro-x:nth-child(33) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(33),
*>.intro-x:nth-child(33) {
  z-index: 17;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.3s;
  animation-delay: 3.3s;
}

*>.-intro-x:nth-child(33) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(33) {
  z-index: 17;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.3s;
  animation-delay: 3.3s;
}

*>.intro-x:nth-child(34) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(34),
*>.intro-x:nth-child(34) {
  z-index: 16;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.4s;
  animation-delay: 3.4s;
}

*>.-intro-x:nth-child(34) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(34) {
  z-index: 16;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.4s;
  animation-delay: 3.4s;
}

*>.intro-x:nth-child(35) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(35),
*>.intro-x:nth-child(35) {
  z-index: 15;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

*>.-intro-x:nth-child(35) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(35) {
  z-index: 15;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

*>.intro-x:nth-child(36) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(36),
*>.intro-x:nth-child(36) {
  z-index: 14;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.6s;
  animation-delay: 3.6s;
}

*>.-intro-x:nth-child(36) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(36) {
  z-index: 14;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.6s;
  animation-delay: 3.6s;
}

*>.intro-x:nth-child(37) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(37),
*>.intro-x:nth-child(37) {
  z-index: 13;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.7s;
  animation-delay: 3.7s;
}

*>.-intro-x:nth-child(37) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(37) {
  z-index: 13;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.7s;
  animation-delay: 3.7s;
}

*>.intro-x:nth-child(38) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(38),
*>.intro-x:nth-child(38) {
  z-index: 12;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.8s;
  animation-delay: 3.8s;
}

*>.-intro-x:nth-child(38) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(38) {
  z-index: 12;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.8s;
  animation-delay: 3.8s;
}

*>.intro-x:nth-child(39) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(39),
*>.intro-x:nth-child(39) {
  z-index: 11;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.9s;
  animation-delay: 3.9s;
}

*>.-intro-x:nth-child(39) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(39) {
  z-index: 11;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 3.9s;
  animation-delay: 3.9s;
}

*>.intro-x:nth-child(40) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(40),
*>.intro-x:nth-child(40) {
  z-index: 10;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

*>.-intro-x:nth-child(40) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(40) {
  z-index: 10;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

*>.intro-x:nth-child(41) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(41),
*>.intro-x:nth-child(41) {
  z-index: 9;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.1s;
  animation-delay: 4.1s;
}

*>.-intro-x:nth-child(41) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(41) {
  z-index: 9;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.1s;
  animation-delay: 4.1s;
}

*>.intro-x:nth-child(42) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(42),
*>.intro-x:nth-child(42) {
  z-index: 8;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.2s;
  animation-delay: 4.2s;
}

*>.-intro-x:nth-child(42) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(42) {
  z-index: 8;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.2s;
  animation-delay: 4.2s;
}

*>.intro-x:nth-child(43) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(43),
*>.intro-x:nth-child(43) {
  z-index: 7;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.3s;
  animation-delay: 4.3s;
}

*>.-intro-x:nth-child(43) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(43) {
  z-index: 7;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.3s;
  animation-delay: 4.3s;
}

*>.intro-x:nth-child(44) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(44),
*>.intro-x:nth-child(44) {
  z-index: 6;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.4s;
  animation-delay: 4.4s;
}

*>.-intro-x:nth-child(44) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(44) {
  z-index: 6;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.4s;
  animation-delay: 4.4s;
}

*>.intro-x:nth-child(45) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(45),
*>.intro-x:nth-child(45) {
  z-index: 5;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

*>.-intro-x:nth-child(45) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(45) {
  z-index: 5;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

*>.intro-x:nth-child(46) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(46),
*>.intro-x:nth-child(46) {
  z-index: 4;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.6s;
  animation-delay: 4.6s;
}

*>.-intro-x:nth-child(46) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(46) {
  z-index: 4;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.6s;
  animation-delay: 4.6s;
}

*>.intro-x:nth-child(47) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(47),
*>.intro-x:nth-child(47) {
  z-index: 3;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.7s;
  animation-delay: 4.7s;
}

*>.-intro-x:nth-child(47) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(47) {
  z-index: 3;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.7s;
  animation-delay: 4.7s;
}

*>.intro-x:nth-child(48) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(48),
*>.intro-x:nth-child(48) {
  z-index: 2;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.8s;
  animation-delay: 4.8s;
}

*>.-intro-x:nth-child(48) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(48) {
  z-index: 2;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.8s;
  animation-delay: 4.8s;
}

*>.intro-x:nth-child(49) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(49),
*>.intro-x:nth-child(49) {
  z-index: 1;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.9s;
  animation-delay: 4.9s;
}

*>.-intro-x:nth-child(49) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(49) {
  z-index: 1;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 4.9s;
  animation-delay: 4.9s;
}

*>.intro-x:nth-child(50) {
  transform: translateX(50px);
}

*>.-intro-x:nth-child(50),
*>.intro-x:nth-child(50) {
  z-index: 0;
  opacity: 0;
  position: relative;
  -webkit-animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  animation: intro-x-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

*>.-intro-x:nth-child(50) {
  transform: translateX(-50px);
}

*>.intro-y:nth-child(50) {
  z-index: 0;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

@-webkit-keyframes intro-x-animation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes intro-x-animation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes intro-y-animation {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes intro-y-animation {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-size: 15px;
  background-position: center right 0.6rem;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  background-repeat: no-repeat;
}

.shadow-b {
  box-shadow: 5px 2px 8px rgba(0, 0, 0, 0.15);
}

.scale {
  -webkit-animation: scale 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

input:checked~.dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
